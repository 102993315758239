module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EventLoop","short_name":"EventLoopLanding","start_url":"/","background_color":"#E5E5E5","theme_color":"#E5E5E5","display":"standalone","icon":"src/assets/img/website-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
